import Layout from "../layouts"
import Container from "../components/container"
import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"
import { MdArrowForward } from "react-icons/md"
import { Heading } from "gatsby-interface"
/** @jsx jsx */
import { Grid, jsx, Box } from "theme-ui"
import SupportForm from "../templates/support/form"

import docsImage from "../assets/support-open-source-docs.svg"
import statusImage from "../assets/support-status-page.svg"
import kbImage from "../assets/support-cloud-knowledge-base.svg"
import roadmapImage from "../assets/support-product-roadmap.svg"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const headerCss = theme => ({
  textAlign: `center`,
  marginBottom: theme.space[12],
  marginTop: theme.space[12],
})

const lightHeadingCss = theme => ({
  color: theme.colors.grey[50],
  fontSize: theme.fontSizes[2],
  letterSpacing: theme.letterSpacings.tracked,
  marginBottom: theme.space[2],
})

const emphasizedHeadingCss = theme => ({
  fontSize: theme.fontSizes[8],
  lineHeight: theme.lineHeights.dense,

  span: {
    color: theme.colors.purple[60],
    display: `block`,
  },

  [theme.mediaQueries.desktop]: {
    fontSize: theme.fontSizes[8],
  },
})

const gridContainer = () => ({
  width: "90%",
  margin: "5rem auto",
})

const itemCss = theme => ({
  textAlign: `center`,
  h2: {
    marginBottom: theme.space[4],
  },
  [theme.mediaQueries.tablet]: {
    textAlign: `left`,
  },

  [theme.mediaQueries.desktop]: {
    marginBottom: theme.space[12],
  },
})

const itemContainerCss = () => ({
  maxWidth: `575px`,
  margin: `0 auto`,
})

const Item = ({ heading, text, link, color, image }) => (
  <Box css={itemCss}>
    <Grid
      gap={[2, 2, 2, `50px`]}
      css={itemContainerCss}
      columns={[
        [1, "1fr"],
        [1, "1fr"],
        [1, "1fr"],
        [2, "125px 1fr"],
      ]}
    >
      <Box>
        <img src={image} alt="" />
      </Box>
      <Box>
        <Heading>
          <OutboundLink
            href={link}
            css={theme => ({
              color: theme.colors[color][60],
              textDecoration: `none`,
              position: `relative`,
            })}
          >
            {heading}{" "}
            <MdArrowForward
              css={theme => ({
                position: `absolute`,
                bottom: theme.space[1],
                right: `-${theme.space[8]}`,
              })}
            />
          </OutboundLink>
        </Heading>
        <p>{text}</p>
      </Box>
    </Grid>
  </Box>
)

function SupportPage() {
  return (
    <Layout>
      <SEO
        title="Gatsby Support | Premium Support and Services"
        description="Get the support and guidance you need to confidently run Gatsby in production."
      />
      <Header />
      <Container>
        <header css={headerCss}>
          <Heading as="h1" variant="LIGHT" css={lightHeadingCss}>
            Looking for help?
          </Heading>
          <Heading as="h2" variant="EMPHASIZED" css={emphasizedHeadingCss}>
            <span>Discover</span> How Gatsby Can Help Support Your Team
          </Heading>
        </header>

        <Grid
          gap="50px"
          columns={[
            [1, "1fr"],
            [1, "1fr"],
            [1, "1fr"],
            [1, "1fr"],
            [2, "1fr 1fr"],
          ]}
          css={gridContainer}
        >
          <Item
            heading="Gatsby Concierge Services"
            text="Professional support, performance audits, and services for Gatsby websites and apps."
            link="https://gatsbyjs.com/concierge"
            color="green"
            image={statusImage}
          />
          <Item
            heading="Gatsby Cloud Knowledge Base"
            text="Explore the functionality available in Gatsby Cloud."
            link="https://support.gatsbyjs.com/hc"
            color="blue"
            image={kbImage}
          />
          <Item
            heading="Open Source Documentation"
            text="Find answers in our comprehensive docs or learn something new in one of our tutorials."
            link="/docs"
            color="orange"
            image={docsImage}
          />
          <Item
            heading="Product Roadmap"
            text="See what we’re working on and what’s coming next in Gatsby Cloud."
            link="http://portal.gatsbyjs.com"
            color="purple"
            image={roadmapImage}
          />
          <Item
            heading="Status Page"
            text="Check the real-time status of Gatsby Cloud and subscribe to updates."
            link="https://status.gatsbyjs.com"
            color="green"
            image={statusImage}
          />
        </Grid>

        <SupportForm />
      </Container>
      <Footer />
    </Layout>
  )
}

export default SupportPage
